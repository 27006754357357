import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"
//import parse from "html-react-parser"
import "../scss/style.scss"
import Navibar from "../components/navibar"
import SectionInfo from "../components/section/section-info"
import SearchSection from "../components/section/search-section"
import TimeBar from "../components/widget/time-widget"
import SectionT5 from "../components/section/section-template"
import SectionT6 from "../components/section/section-template-6"

//var Sticky = new hcSticky('#sidebar', {
//  stickTo: '#content'
//});

const WebPageTemplate = ({ data, location }) => {
    
    let cate_list = [];

    data.allWpCategory.edges.filter(edge => edge.node.wpParent? edge.node.wpParent.node.slug == 'navigation': false)
      .sort((a, b)=>(a.node.slug<b.node.slug?-1:1))
      .map(edge => cate_list.push({link: "#"+edge.node.slug, slug: edge.node.slug, name: edge.node.name}))

    return (
        <>
        <Helmet>
          <meta charSet="utf-8" />
        </Helmet>
        <Seo title={process.env.SEO_TITLE}
             description={process.env.SEO_DESCRIPTION} 
                         lang="zh-Hans" />
        <Layout location={location}>
            <div id="hero" className="is-small mb-5 p-5 has-background-white">
              <div className="container">
                <SearchSection></SearchSection>
              </div>
            </div>
            <div id="content" className="container is-fullwidth">
                <div className="columns is-mobile is-multiline is-centered">
                  <div id="sidebar" className="column is-1-desktop is-2-tablet is-hidden-mobile">
                    <Navibar data={ cate_list }></Navibar>
                  </div>
                  <div className="column is-11-desktop is-9-tablet is-11-mobile">
                    <div id="timebar" className="is-hidden-mobile"><TimeBar /></div>
                    {/*<SectionOne data={ data.allWpPost } cate_id="s1c1"></SectionOne>*/}
                    <SectionInfo data={ data } cate_id="s2c2"></SectionInfo>
                    {
                      cate_list.map(
                        ( cate, key ) => (
                          <SectionT6 key={key} data={ data } cate_id={cate.slug}></SectionT6>
                        )
                      )
                    }
                    <SectionT5 data={data} cate_id="s5c1"></SectionT5>
                  </div>
                </div>
            </div>
        </Layout>
        </>
    )
}

export default WebPageTemplate

export const pageQuery = graphql`
  query SectionQuery {
    allWpPost(filter: {sites: {isNavigator: {eq: true}}}) {
      edges {
        node {
          title
          slug
          sites {
            isNavigator
            websiteUrl
            websiteDescription
            websiteIcon {
              alt: altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          iconImg{
            childImageSharp {
              gatsbyImageData(width: 16)
            }
          }
          categories {
            nodes {
              slug
              wpParent{
                node{
                  slug
                }
              }
            }
          }
        }
      }
    }
    allWpCategory {
      edges {
          node {
              id
              name
              slug
              parentId
              wpParent {
                node {
                  slug
                  databaseId
                }
              }
          }
      }
    }
  }
`