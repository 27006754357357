import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAdn } from "@fortawesome/free-brands-svg-icons"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"

const SectionT6 = ( { data, cate_id, style } ) => {
    //TODO: accept style classes from WPsite
    const defaultStyle = {
        section: {
            style: "mb-4",
            cusCss: "",
        },
        subsection: {
            style: "",
            cusCss: "",
        },
        header: {
            style: "tabs is-size-6 pb-1 mb-2",
            cusCss: "",
        },
        body: {
            style: "columns is-multiline m-0",
            itemList: {
                style: "level is-2",
            },
            item: {
                style: "friendLink",
                title: {
                    style: "m-2 is-size-6",
                }, 
                description: {
                    style: "content is-size-7",
                    display: false,
                }
            }
        }
    }

    if ( !style ){
        style = defaultStyle
    }

    let cate_list = [];
    /** The Tabs' parent category should be "cate_id" */
    data.allWpCategory.edges.filter(edge=> 
        edge.node.parentId && edge.node.wpParent.node.slug === cate_id)
            .sort((a, b)=>(a.node.slug<b.node.slug?-1:1))
            .map(edge=>
                cate_list.push({id: edge.node.id, slug: edge.node.slug, name: edge.node.name})
            )

    return (
        <>
        <section id={cate_id} className={defaultStyle.section.style}>
            <h2 className="is-size-5 mb-3"><span className="icon is-small mr-2"><FontAwesomeIcon icon={faAdn} /></span>{data.allWpCategory.edges.reduce((total, edge) => total += edge.node.slug === cate_id?edge.node.name:'','')}</h2>
            <Tabs>
                {
                    cate_list.length > 1?
                <div className="tabs is-toggle is-toggle-rounded p-0">
                    <TabList>
                        {
                            cate_list.map(cate=>(
                        <Tab key={cate.id}>
                            <Link>
                                <h3>{cate.name}</h3>
                            </Link>
                        </Tab>
                            ))
                        }
                    </TabList>
                </div>
                :''
                }
                {cate_list.map(cate=>(
                    <TabPanel>
                        <div key={cate.id} className="columns is-multiline is-flex-mobile">
                            {
                                data.allWpPost.edges
                                .filter(edge => edge.node.categories.nodes
                                    .reduce((arg,node) => arg += (node.slug === cate.slug)?1:arg,0))
                                        .map((edge, key) =>{
                                            var embedImage = getImage(edge.node.iconImg)?
                                                <GatsbyImage image={getImage(edge.node.iconImg)} alt={edge.node.title} />:
                                                <img src={"https://s2.googleusercontent.com/s2/favicons?sz=576&domain="+edge.node.sites.websiteUrl} alt={edge.node.title} />;
                                            return (
                            <a href={'/sites/' + edge.node.slug} key={key} className="column is-one-fifth-desktop is-4-tablet is-half-mobile ">
                                <div className="capsule on-hover-highlight media p-3">
                                    <figure className="image is-16x16 m-1">{embedImage}</figure>
                                    <div className="media-content">
                                        <p className="is-size-6 has-text-weight-medium">{edge.node.title}</p>
                                        <p className="content is-size-7">{edge.node.sites.websiteDescription}</p>
                                    </div>
                                </div>
                            </a>
                                        )})
                            }
                        </div>
                    </TabPanel>
                            )
                        )
                }
            </Tabs>
        </section>
        </>
    )
}

export default SectionT6
