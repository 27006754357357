import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <div className="container is-fullwidth">
        <div className="columns is-vcentered">
          <div className="column has-text-centered">
            <h1 className="title is-1 is-right">404: 没找到</h1>
            <p>页面好像丢失了。。。真是尴尬</p>
          </div>
          <div className="column">
            <StaticImage src="images/14.png" width="800" placeholder="none" alt="404 Page Not Found"></StaticImage>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
