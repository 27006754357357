import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import Layout from "../../components/layout"
import BreadCrumb from "../../components/widget/breadcrumb"
import NewestArticleBar from "../../components/sidebar/article-bar"
//import { GatsbyImage } from "gatsby-plugin-image/dist/src/components/gatsby-image.browser"

const NotFoundPage = ({ data, location }) => {

  return (
    <Layout location={location} title={ data.post.title }>
      <div className="container is-fullwidth">
        <BreadCrumb pageurl={location.pathname} 
                  title={ data.post.title } 
                  description={ data.post.sites.websiteDescription ? 
                    data.post.sites.websiteDescription : 
                    data.post.title + process.env.LINK_DEFAULT_DESCRIPTION } />
        <div className="columns is-mobile is-multiline is-centered">
          <div className="column is-three-quarters-desktop is-11-mobile">
            <div className="card is-vcentered">
              <div className="card-content">
                <h1 className="title">{ data.post.title }</h1>
                <p className="description">{ data.post.sites.websiteDescription }</p>
                <p className="description">{ data.post.sites.websiteUrl }</p>
                <div className="field is-grouped mt-3">
                  <p className="control">
                    <a className="button" href={ data.post.sites.websiteUrl } rel="nofollow">
                      访问站点
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 mb-2">最新站点</div>
            <div className="columns is-multiline is-flex-mobile">
              {
                data.newsites.edges.map( ({node}) => {
                  var embedImage = getImage(node.iconImg)?
                                            <GatsbyImage image={getImage(node.iconImg)} alt={node.title} />:
                                            <img src={"https://s2.googleusercontent.com/s2/favicons?sz=576&domain="+node.sites.websiteUrl} alt={node.title} />;
                  return (
                  <div key={node.id} className="column is-3-desktop is-half-tablet is-half-mobile">
                      <div className="capsule on-hover-highlight media p-3">
                          <figure class="image is-16x16 m-1">
                            {embedImage}
                          </figure>
                        <div class="media-content">
                          <Link to={"/sites/" + node.slug}>
                            <p class="is-size-6">{ node.title }</p>
                          </Link>
                          <p class="content is-size-7">{ node.sites.websiteDescription }&nbsp;</p>
                        </div>
                      </div>
                  </div>
                )})
              }
              
            </div>
          </div>
          <div className="column is-one-quarters-desktop is-11-mobile">
            <NewestArticleBar></NewestArticleBar>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query SiteDetailById(
    $id: String!
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      title
      date(formatString: "MMMM DD, YYYY")
      sites {
        websiteUrl
        websiteDescription
        websiteIcon {
          gatsbyImage(
            width: 300, 
            layout: CONSTRAINED
            )
        }
      }
    }
    newsites: allWpPost(
      filter: {sites: {isNavigator: {eq: true}}}
      sort: {fields: [date], order: DESC}
      limit: 8
    ) {
      edges {
        node {
          id
          slug
          title
          sites {
            websiteDescription
          }
          iconImg{
            childImageSharp {
              gatsbyImageData(width: 16)
            }
          }
        }
      }
    }
  }
`