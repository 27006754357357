import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { Link } from "gatsby"

const SearchSection = () => {

    const [activeTab, setActiveTab] = useState('1')
    const [searchInput, setSearchInput] = useState('')

    const tabs = [
        {
            id:1,
            title: '百度搜索',
            url: 'https://www.baidu.com/s?wd=',
        },
        // {
        //     id:2,
        //     title: '站内搜索',
        //     url: 'https://navi.abc',
        // },
        {
            id:2,
            title: 'Google搜索',
            url: 'https://www.google.com/search?q=',
        },
        {
            id:3,
            title: 'Yandex搜索',
            url: 'https://yandex.com/search/?text=',
        },
        {
            id:4,
            title: '搜狗搜索',
            url: 'https://sougou.com',
        },
    ];

    //switch tab and set activeTab value to active tab
    const handleactiveTabClick = (event) => {
        setActiveTab(event.target.id.slice(-1));
        console.log(event);
    }

    //check if current tab is active
    const isTabActive = (id) => {
        return activeTab == id
    }

    //click search button trigger this event
    const handleSearchButtonClick = () => {
        if (searchInput) {
            window.open(tabs[activeTab-1].url + searchInput, '_blank').focus();
        }
    }

    //press Enter to make a search
    const keyDownHandler = (event) => {
        console.log('User pressed: ', event.key);
        event.target.value ? setSearchInput(event.target.value) : setSearchInput('')       
        
        if (event.key === 'Enter' && searchInput) {
            window.open(tabs[activeTab-1].url + searchInput, '_blank').focus();
            console.log(event);
        }
    };


    return (
        <>
        <section id="search-section" className="columns is-vcentered is-centered">
            <div className="column is-3-desktop is-centered is-hidden-mobile">
                <h1 class="is-hidden">04q.com 华文站点导航</h1>
                <Link className="" to="/">
                    <figure className="image m-2"><StaticImage src="logo-main.png" layout="fixed" height="90" placeholder="none" /></figure>
                </Link>
            </div>
            <div className="column is-7-desktop">
                <div id="search-tab" className="is-inline-block is-flex-wrap-wrap mb-0">
                    <ul>
                        {tabs.map((tab, key) => (
                            <li key={key} className={isTabActive(tab.id) ? 'is-active is-inline-block mx-1':'is-inline-block mx-1'}>
                                <a id={'activeTab-' + tab.id} onClick={handleactiveTabClick}>{tab.title}</a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="field has-addons">
                    <div className="control is-expanded">
                        <input id="searchbar-input" className="input is-rounded" type="text" placeholder={tabs[activeTab-1].title} onKeyPress={keyDownHandler} />
                    </div>
                    <div className="control">
                        <a id="searchbar-button" class="button is-rounded" onClick={handleSearchButtonClick} >搜索</a>
                    </div>
                </div>
            </div>
        </section>
        </>
    ) 
     
}

export default SearchSection
