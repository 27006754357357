import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
//import parse from "html-react-parser"
//import { GatsbyImage, StaticImage, getImage} from "gatsby-plugin-image"
//import { getSrc } from "gatsby-plugin-image"

const SectionInfo = ( { data, cate_id } ) => {
    //variables can only be passed to GraphQL query via Context, that's why filter part is not rewritten
    const articleQuery = useStaticQuery(graphql`
        query DualArticleQuery {
            leftArticleQuery: allWpPost(
                filter: {articles: {isArticle: {eq: true}}, categories: {nodes: {elemMatch: {slug: {eq: "raiders"}}}}}
                sort: {fields: [date], order: DESC}
                limit: 6
            ) {
                ...queryFields
            }
            rightArticleQuery: allWpPost(
                filter: {articles: {isArticle: {eq: true}}, categories: {nodes: {elemMatch: {slug: {eq: "practicals"}}}}}
                sort: {fields: [date], order: DESC}
                limit: 6
            ) {
                ...queryFields
            }
        }
        
        fragment queryFields on WpPostConnection {
            edges {
            node {
                uri
                title
                categories {
                nodes {
                    name
                }
                }
            }
            }
        }
    `)
    // can be set to hide/show more articles in the block, only to add a slice(0, articleNumber) to the list query
    //const [articleNumber, setArticleNumber] = useState(3)

    return (
        <>
        <section id={cate_id} className="columns">
            <div className="column block">
                <div className="columns">
                    <div key="col-0" className="column box m-3">
                        <div className="level m-0">
                            <span className="level-left"></span>
                            <span className="level-right"><Link className="level-item tag is-info" to="/articles/raiders">更多 &gt;&gt;</Link></span>
                        </div>
                        <div className="pl-5 pr-5 pb-3">
                            <ul id="article-list-1">
                                {
                                    articleQuery.leftArticleQuery.edges.map( (edges, key) => (
                                        <li key={key}><Link to={edges.node.uri} >[{edges.node.categories.nodes[0].name}] {edges.node.title}</Link></li>
                                    ))
                                }
                            </ul>
                        </div>
                        {/*<div className="level m-0">
                            <span className="level-left"></span>
                            <span className="level-right"><button className="level-item tag is-info is-light" onClick={() => setArticleNumber(6)} >展开 >></button></span>
                            </div>*/}
                    </div>
                    <div key="col-1" className="column box m-3">
                        <div className="level m-0">
                            <span className="level-left"></span>
                            <span className="level-right"><a className="level-item tag is-info" href="/articles/practicals">更多 &gt;&gt;</a></span>
                        </div>
                        <div className="pl-5 pr-5 pb-3">
                            <ul>
                                {
                                    articleQuery.rightArticleQuery.edges.map( (edges, key) => (
                                        <li key={key}><Link to={edges.node.uri} >[{edges.node.categories.nodes[0].name}] {edges.node.title}</Link></li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default SectionInfo
