import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const NewestArticleBar = () => {
    const { allWpPost } = useStaticQuery(graphql`
        query{
            allWpPost(
                filter: {articles: {isArticle: {eq: true}}}
                sort: {fields: [date], order: DESC}, limit: 5
                ) {
                edges {
                  node {
                    id
                    uri
                    title
                  }
                }
            }
        }
    `)

    return (
        <>
        <div className="panel box is-link p-0">
            <p className="panel-heading">最新文章</p>
            <div className="panel-block">
            {!!allWpPost.edges && (
                <div className="p-5">
                <ul>
                    {allWpPost.edges.map(edge => (
                    <li key={edge.node.id}><Link to={edge.node.uri}>{edge.node.title}</Link></li>
                    ))}
                </ul>
                </div>
            )}
            </div>
        </div>
        </>
    )
}

export default NewestArticleBar
