import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

//import Bio from "../components/bio"
import Layout from "../../components/layout"
import BreadCrumb from "../../components/widget/breadcrumb"

const ArticleIndex = ({data, location, pageContext: { nextPagePath, previousPagePath }}) => {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return (
      <Layout location={location}>
        <div className="container is-fullwidth">
          <p>
            No blog posts found. Add posts to your WordPress site and they'll
            appear here!
          </p>
        </div>
      </Layout>
    )
  }

  return (
    <Layout location={location}>
      <div className="container is-fullwidth">
        <BreadCrumb pageurl = {location.pathname} title={isNaN(location.pathname.split('/')[-1])?location.pathname.split('/')[-1]:location.pathname.split('/')[-2]}/>
        <div className="columns is-mobile is-multiline is-centered">
          <div className="column is-11-mobile">
            <ul>
              {posts.map(post => {
                const title = post.title

                return (
                  <li key={post.uri}>
                    <div className="box mb-4">
                      <article className="media" itemScope itemType="http://schema.org/Article">
                        <div className="media-content">
                          <h2>
                            <Link to={post.uri} itemProp="url">
                              <span itemProp="headline">{parse(title)}</span>
                            </Link>
                          </h2>
                          <small>{post.date}</small>
                          <div itemProp="description">{parse(post.excerpt)}</div>
                        </div>
                      </article>
                    </div>
                  </li>
                )
              })}
            </ul>
            <div className="level mt-4">
              <div className="level-left">
                {previousPagePath && (
                  <>
                    <Link to={previousPagePath} class="button is-link is-light">上一页</Link>
                    <br />
                  </>
                )}
              </div>
              <div className="level-right">
                {nextPagePath && <Link to={nextPagePath} class="button is-link">下一页</Link>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ArticleIndex

export const pageQuery = graphql`
  query ArticlePostArchive($offset: Int!, $postsPerPage: Int!, $filter: WpPostFilterInput) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: $filter
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
      }
    }
  }
`
