import React, { useEffect, useState } from "react"
import { GatsbyImage, StaticImage, getImage} from "gatsby-plugin-image"

const TimeBar = () => {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => setDate(new Date()), 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
        <section className="level level-is-shrinkable mb-1 has-text-centered is-multiline">
            <div key="bjtime" className="level-item"><StaticImage className="mr-1" src="images/cn.png" width="20" /> 北京 {calculateTime(date, "Asia/Shanghai")}</div>
            <div key="vatime" className="level-item"><StaticImage className="mr-1" src="images/ca.png" width="20" /> 温哥华 {calculateTime(date, "America/Vancouver")}</div>
            <div key="chtime" className="level-item"><StaticImage className="mr-1" src="images/us.png" width="20" /> 芝加哥 {calculateTime(date, "America/Chicago")}</div>
            <div key="totime" className="level-item"><StaticImage className="mr-1" src="images/ca.png" width="20" /> 多伦多 {calculateTime(date, "America/Toronto")}</div>
            <div key="nytime" className="level-item"><StaticImage className="mr-1" src="images/us.png" width="20" /> 纽约 {calculateTime(date, "America/New_York")}</div>
            <div key="hatime" className="level-item"><StaticImage className="mr-1" src="images/ca.png" width="20" /> 哈利法克斯 {calculateTime(date, "America/Halifax")}</div>
        </section>
        </>
    )
}

export default TimeBar

function calculateTime(newdate, timeZone){
    const option = {
        day : "2-digit",
        month : "2-digit",
        year : "numeric",
        hour12 : false,
        hour : "numeric",
        minute : "2-digit",
        second : "2-digit",
    };
    option.timeZone = timeZone;
    
    let now = newdate ? newdate : new Date();
    let str = now.toLocaleDateString('en', option);

    //split time
    let splice = str.split(',');
    let month = splice[0].split('/')[0];
    let day = splice[0].split('/')[1];
    let time = splice[1];

    //order time to format DD/MM/YYYY
    let time_format = month + '/' + day + ' ' + time;
    
    return time_format
}