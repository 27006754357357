import React from "react"
//import { Link, useStaticQuery, graphql } from "gatsby"
//import parse from "html-react-parser"
//import { GatsbyImage, getImage} from "gatsby-plugin-image"
//import { getSrc } from "gatsby-plugin-image"

const SectionT5 = ( { data, cate_id, style } ) => {
    const defaultStyle = {
        section: {
            style: "",
            cusCss: "",
        },
        subsection: {
            style: "",
            cusCss: "",
        },
        header: {
            style: "tabs is-size-6 pb-1 mb-2",
            cusCss: "",
        },
        body: {
            style: "columns is-multiline m-0",
            itemList: {
                style: "level is-2",
            },
            item: {
                style: "friendLink",
                title: {
                    style: "m-2 is-size-6",
                }, 
                description: {
                    style: "content is-size-7",
                    display: false,
                }
            }
        }
    }

    if ( !style ){
        style = defaultStyle
    }

    return (
        <>
        <section id={cate_id} className={ style.section.style }>
            <div className={ style.subsection.style }>
                <div className={ style.header.style }>
                    <ul>
                        <li>
                            <a>
                            <span>{data.allWpCategory.edges.reduce((arg, edge) => (arg = edge.node.slug !== cate_id?arg:edge.node.name),"")}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className={ style.body.style }>
                    <div className={ style.body.itemList.style }>
                    
                        {
                            data.allWpPost.edges
                                .filter(edge => edge.node.categories.nodes
                                    .reduce((arg,node) => arg += (node.slug === cate_id)?1:arg,false))
                                        .map((edge, key) =>{
                                            return (
                        <div key={key} className={ style.body.item.style }> 
                                <a href={edge.node.sites.websiteUrl}><span className={ style.body.item.title.style }>{edge.node.title}</span></a>
                                { style.body.item.description.display?<p className={ style.body.item.description.style }>{edge.node.sites.websiteDescription}</p>:"" }
                        </div>
                                            )}
                                        )
                        }
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default SectionT5
