import React from "react"
import { Link } from "gatsby"
import { Sticky, StickyContainer } from "react-sticky"
//import hcSticky from "hc-sticky"
//import parse from "html-react-parser"


const Navibar = ( { data } ) => {

    return (
        <>
        <StickyContainer> 
            <Sticky>
                {
                    ({style}) => (
                        <aside id="aside" className="menu has-text-left" style={style}>
                            <div id="nav-section" >
                                <p className="menu-label">
                                    页面导航
                                </p>
                                <ul className="menu-list">
                                    {
                                        //data.edges.filter(edge => edge.node.wpParent? edge.node.wpParent.node.slug == 'navigation': false)
                                        //    .sort((a, b)=>(a.node.slug<b.node.slug?-1:1)
                                        //    )
                                        //    .map((edge, key) => (
                                        //        <li key={key}><Link to={"#"+edge.node.slug} >{edge.node.name}</Link></li>
                                        //    ))
                                        data.map((cate, key) =>
                                            <li key={key}><Link to={cate.link} className="px-0">{cate.name}</Link></li>
                                        )
                                    }
                                </ul>
                            </div>
                        </aside>
                    )
                }
                
            </Sticky>
        </StickyContainer>
        
        </>
    )
}

export default Navibar