import React from "react"
import { Link, graphql, Script } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version

import Layout from "../../components/layout"
import BreadCrumb from "../../components/widget/breadcrumb"
import NewestArticleBar from "../../components/sidebar/article-bar"

const BlogPostTemplate = ({ location, data: { previous, next, post, newsList } }) => {
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }

  return (
    <Layout location={location}>
      <Script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-62f00593e5ef0e23"></Script>
      <article className="" itemScope itemType="http://schema.org/Article">
        <div className="container is-fullwidth">
        <BreadCrumb pageurl={location.pathname} title={post.title} description={post.excerpt}></BreadCrumb>
        <div className="columns is-mobile is-multiline is-centered">
            <div className="column block box p-5 is-9-desktop is-11-tablet is-11-mobile">
              <header>
                <h1 className="title" itemProp="headline">{parse(post.title)}</h1>
                <p>{post.date}</p>
                <div className="addthis_inline_share_toolbox"></div>
                {/* if we have a featured image for this post let's display it */}
                {featuredImage?.data && (
                  <GatsbyImage className="mb-5" image={featuredImage.data} alt={featuredImage.alt} />
                )}
              </header>
              <div className="content mt-5">
                {!!post.content && (
                  <section itemProp="articleBody">{parse(post.content)}</section>
                )}
              </div>
            </div>
            <div className="column pl-5-desktop pt-0 is-3-desktop is-11-tablet is-11-mobile">
              <NewestArticleBar></NewestArticleBar>
            </div>
        </div>
        <hr />

        <footer>
        </footer>
        </div>
      </article>

      <nav className="blog-post-nav">
        <ul style={{ display: `flex`, flexWrap: `wrap`, justifyContent: `space-between`, listStyle: `none`, padding: 0,}}>
          <li>
            {previous && (
              <Link to={previous.uri} rel="prev">
                ← {parse(previous.title)}
              </Link>
            )}
          </li>

          <li>
            {next && (
              <Link to={next.uri} rel="next">
                {parse(next.title)} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query ArticleBlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      databaseId
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
    newsList: allWpPost(
      filter: {articles: {isArticle: {eq: true}}}
      sort: {fields: [date], order: DESC}, limit: 5
      ) {
      edges {
        node {
          id
          uri
          title
        }
      }
    }
  }
`
